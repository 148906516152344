<template>
  <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4" flat>
      <v-row>
        <v-col xl="4" lg="4" md="12" sm="12">
          <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
            <div>
              <v-img
                width="52"
                class="mr-4"
                src="@/assets/images/person/wallet1.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5 font-weight-bold">
                ¥ {{ moneyinfo.ktx_money }}
              </div>
              <div>{{ $t("钱包余额") }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col xl="4" lg="4" md="12" sm="12">
          <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
            <div>
              <v-img
                class="mr-4"
                width="52"
                src="@/assets/images/person/wallet2.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5 font-weight-bold">
                ¥ {{ moneyinfo.txz_money }}
              </div>
              <div>{{ $t("提现中的金额") }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col xl="4" lg="4" md="12" sm="12">
          <v-card flat class="pa-4 d-flex align-center" color="#F7F7F7">
            <div>
              <v-img
                class="mr-4"
                width="52"
                src="@/assets/images/person/wallet3.png"
              ></v-img>
            </div>
            <div class="text-left">
              <div class="mb-2 text-h5 font-weight-bold">
                ¥ {{ moneyinfo.ytx_money }}
              </div>
              <div>{{ $t("已提现金额") }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-body-1 font-weight-bold mt-4">{{ $t("提现方式") }}</div>
      <v-tabs color="#F63605" v-model="tab" @change="tabChange">
        <!-- <v-tab>{{ $t("提现至支付宝") }}</v-tab> -->
        <v-tab v-for="(bank, indexb) in WithdList" :key="indexb">{{
          bank.name
        }}</v-tab>
      </v-tabs>
      <div>
        <div class="d-flex align-center mt-2 mb-2" v-if="Withdinfo.name=='支付宝'">
          <div>
            <v-img
              class="mr-4"
              width="100"
              src="@/assets/images/person/zfb.png"
            ></v-img>
          </div>
        </div>
        <div class="d-flex align-center mt-2 mb-2" v-else>
          <div>
            <v-img
              class="mr-4"
              width="52"
              src="@/assets/images/person/bank-logo.png"
            ></v-img>
          </div>
          <div class="text-left">
            <div class="text-sharered">BANK</div>
            <div class="">Vietnam</div>
            <div>China</div>
          </div>
        </div>
        <div>
          <span class="text-body-1 font-weight-bold">{{
            $t("可提现金额：")
          }}</span>
          <span class="text-sharered text-htext-body-1 font-weight-bold"
            >￥{{ moneyinfo.ktx_money }}</span
          >
        </div>
      </div>

      <v-card
        flat
        class="d-flex pa-2 flex-between mt-4"
        width="360"
        color="#F7F7F7"
      >
        <span>
          <span>￥</span>
          <input
            style="outline: none; width: 250px"
            type="number"
            v-model="tixianmoney"
            :placeholder="$t('请输入提现金额')"
          />
        </span>
        <span
          class="text-sharered cursor"
          @click="tixianmoney = moneyinfo.ktx_money"
          >{{ $t("全部提现") }}</span
        >
      </v-card>
      <div class="mt-4" v-if="Withdinfo">
        {{$t('汇率：')}}1RMB = {{parseFloat(Withdinfo.hv_rate)  }}{{ Withdinfo.currency_symbol }}
      </div>
      <div class="text-body-1 font-weight-bold mt-8">
        {{ $t("用户账号：") }}
      </div>
      <div>
        <div v-if="Withdinfo">
          <v-radio-group hide-details v-model="adultOnly" class="mt-0">
            <v-radio
              v-for="(bans, indexs) in Withdinfo.user_account"
              :key="indexs"
              :label="bans.name + '-' + bans.bank_name + '-' + bans.code"
              :value="bans.id"
            ></v-radio>
          </v-radio-group>
        </div>
        <div v-if="Withdinfo.name=='支付宝'" class="text-sharered ml-10 mt-4 cursor" @click="zfbdialog = true">
          {{ $t("添加支付宝账户") }}
        </div>
        <div v-else class="text-sharered ml-10 mt-4 cursor" @click="showdia('bank')">
          {{ $t("添加银行卡") }}
        </div>
      </div>
      <v-btn class="mt-4" width="160" color="primary" @click="submitW">{{
        $t("确认提现")
      }}</v-btn>
    </v-card>
    <!-- 添加支付宝账号弹窗 -->
    <v-dialog v-model="zfbdialog" width="450">
      <v-card class="pa-4">
        <p class="text-body-1 font-weight-bold">{{ $t("添加支付宝账户") }}</p>
        <p class="text-14 mb-2">{{ $t("姓名") }}</p>
        <v-text-field
          :placeholder="$t('请输入个人信息')"
          :rules="Rules"
          outlined
          dense
          class="mb-4"
          v-model="zfdata.name"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("支付宝账号") }}</p>
        <v-text-field
          :placeholder="$t('请输入账号')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="zfdata.code"
        ></v-text-field>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="zfbdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitCode('zfb')"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 添加银行卡账号弹窗 -->
    <v-dialog v-model="bankdialog" width="450">
      <v-card class="pa-4">
        <p class="text-body-1 font-weight-bold">{{ $t("添加银行卡账户") }}</p>
        <p class="text-14 mb-2">{{ $t("姓名") }}</p>
        <v-text-field
          :placeholder="$t('请输入个人信息')"
          :rules="Rules"
          outlined
          dense
          class="mb-4"
          v-model="bankdata.name"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("银行卡号") }}</p>
        <v-text-field
          :placeholder="$t('请输入账号')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="bankdata.bankcode"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("银行") }}</p>
        <v-text-field
          :placeholder="$t('请输入银行')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="bankdata.bank"
        ></v-text-field>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="bankdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitCode('bank')"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "withdrawa",
  data() {
    return {
      tab: 0,
      zfbdialog: false,
      bankdialog: false,
      moneyinfo: {},
      WithdList: [], //提现方式列表
      Withdinfo: {}, //提现信息，汇率
      tixianmoney: "", //提现金额
      zfdata: {
        name: "",
        code: "",
      },
      bankdata: {
        name: "",
        bankcode: "",
        bank: "",
      },
      Rules: [(v) => !!v || "必填项"],
      adultOnly: "",
      zfbcode: "ADULT_ONLY",
    };
  },
  mounted() {
    this.getWithdrawalType();
    this.getMoney();
  },
  methods: {
    getMoney() {
      this.$api.user.getMoney().then((res) => {
        if (res.status == "success") {
          this.moneyinfo = res.data;
        } else {
          this.moneyinfo = {};
        }
      });
    },
    // 获取提现方式
    getWithdrawalType() {
      this.$api.order.getWithdrawalType().then((res) => {
        this.Withdinfo = res.data[0];
        this.WithdList = res.data;
      });
    },
    // 确认提现
    submitW(){
      let param={
        amount:this.tixianmoney,
        currency:this.Withdinfo.currency_symbol,
        hv_rate:this.Withdinfo.hv_rate,
        a_id:this.adultOnly
      }
      if(this.tixianmoney=='' || this.tixianmoney<=0){
        this.$Toast({ content: this.$t("请输入正确提现金额"), type: "error" });
        return false;
      }
      if(this.adultOnly==''){
        this.$Toast({ content: this.$t("请选择提现账号"), type: "error" });
        return false;
      }
      this.$api.user.applyWithdrawal(param).then((res)=>{
        if (res.status == "success") {
          this.$Toast({ content: res.msg, type: "success" });
          this.tixianmoney=''
          this.tab=0
          this.getMoney();

          this.getWithdrawalType()
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      })
    },
    // 提交添加信息
    submitCode(value) {
      if(value=='bank'){
        this.bank(value);
      }else {
        this.addZFb()
      }
    },

    bank(val) {
      let param={}
      if(val == "bank"){
        param = {
          name: this.bankdata.name,
          code: this.bankdata.bankcode,
          bank_name: this.bankdata.bank,
          cid: this.Withdinfo.id,
        };
        if (this.bankdata.name == "") {
          this.$Toast({ content: this.$t("请填写姓名"), type: "error" });
          return false;
        }
        if (this.bankdata.bankcode == "") {
          this.$Toast({ content: this.$t("请填写银行卡号"), type: "error" });
          return false;
        }
        if (this.bankdata.bank == "") {
          this.$Toast({ content: this.$t("请填写银行名称"), type: "error" });
          return false;
        }
      }
      this.$api.order.addBank(param).then((res) => {
        if (res.status == "success") {
          this.$Toast({ content: res.msg, type: "success" });
          this.bankdialog = false;
          this.getWithdrawalType();
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    addZFb(){
      let param = {
          name: this.zfdata.name,
          code: this.zfdata.code,
          bank_name: '支付宝',
          cid: this.Withdinfo.id,
        };
        if (this.zfdata.name == "") {
          this.$Toast({ content: this.$t("请填写姓名"), type: "error" });
          return false;
        }
        if (this.zfdata.code == "") {
          this.$Toast({ content: this.$t("请填写支付宝号"), type: "error" });
          return false;
        }
        this.$api.order.addZFB(param).then((res) => {
        if (res.status == "success") {
          this.$Toast({ content: res.msg, type: "success" });
          this.zfbdialog = false;
          this.getWithdrawalType();
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    showdia(val) {
      if (val == "bank") {
        this.bankdialog = true;
        this.bankdata = {
          name: "",
          bankcode: "",
          bank: "",
        };
      } else {
        this.zfbdialog = true;
        this.zfdata = {
          name: "",
          code: "",
        };
      }
    },
    // 切换tab栏
    tabChange(e) {
      this.tab=e
      this.Withdinfo = this.WithdList[e];
      this.adultOnly=""
    },
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
.text-sharered {
  color: #f63605;
}
.flex-between {
  justify-content: space-between;
}
</style>